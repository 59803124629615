<template>
    <div class="modal" style="display: block;">
        <!-- DEPART -->
        <div class="modal-content" v-if="!success">
            <!-- MESSAGE DEPART -->
            <h2 class="title" v-if="!hasError">ANATOMIE FONCTIONNELLE DE LA MAIN <br>
            <span class="subtitle">Glissez et déposez les éléments au bon endroit</span></h2>

            <!-- MESSAGE RESULTATS KO -->
            <p v-if="hasError">ANATOMIE FONCTIONNELLE DE LA MAIN<br>
            <span class="red">Il semblerait qu’il y ait une ou plusieurs mauvaises réponses.</span></p>

            <!-- DRAG & DROP -->
            <div id="ddzone" class="dragdrop">
                <div class="handle q1" id="qulna" :class="{'selected' : false, 'right': isWellPlaced('ulna')}" data-rep="ulna">Ulna</div>
                <div class="handle q2" id="qligaments" :class="{'selected' : false, 'right': isWellPlaced('ligaments')}" data-rep="ligaments">Ligaments du poignet</div>
                <div class="handle q3" id="qradius" :class="{'selected' : false, 'right': isWellPlaced('radius')}" data-rep="radius">Radius</div>
                <div class="handle q4" id="qcarpo" :class="{'selected' : false, 'right': isWellPlaced('carpo')}" data-rep="carpo">Articulation carpo métacarpienne</div>
                <div class="handle q5" id="qphalanges" :class="{'selected' : false, 'right': isWellPlaced('phalanges')}" data-rep="phalanges">Phalanges</div>
                <div class="handle q6" id="qradio" :class="{'selected' : false, 'right': isWellPlaced('radio')}" data-rep="radio">Articulation radio carpienne</div>
                <div class="handle q7" id="qmeta" :class="{'selected' : false, 'right': isWellPlaced('meta')}" data-rep="meta">Métacarpien</div>

                <div class="dropzone">
                    <!-- Etat par défaut -->
                    <ul class="drop">
                        <li id="r1" class="r1"></li>
                        <li id="r2" class="r2"></li>
                        <li id="r3" class="r3"></li>
                        <li id="r4" class="r4"></li>
                        <li id="r5" class="r5"></li>
                    </ul>

                    <div class="visuel">
                        <img src="img/ressources/ressource-main.jpg" alt="Main">
                    </div>

                    <!-- Etat par défaut -->
                    <ul class="drop drop2">
                        <li id="r6" class="r6"></li>
                        <li id="r7" class="r7"></li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- RESULTATS BONS -->
        <div class="modal-content bravo" v-if="success">
            <!-- MESSAGE RESULTATS OK -->
            <h2>BRAVO !</h2>
            <p>Vous avez mis tous les éléments au bon endroit</p>

            <!-- VISUEL OK -->
            <div class="visuel">
                <img src="img/ressources/ressource-main-result.jpg" alt="Anatomie main">
            </div>
        </div>

        <!-- MODAL FOOTER -->
        <div class="modal-footer">
            <!-- BTN VALIDER -->
            <button type="button" class="btn btn-blue align-center" @click="validate()" v-if="!success"><span>Valider</span></button>

            <button v-if="hasError === false && !isAlreadyInDossier" type="button" class="btn btn-blue align-center" @click="addToDossier()"><span>Ajouter aux ressources</span></button>

            <!-- BTN SUIVANT - AFFICHER UNE FOIS QUE LE USER A VALIDE CETTE ETAPE POUR PASSER A LA PROCHAINE -->
            <button v-if="hasError === false && isAlreadyInDossier" type="button" class="btn btn-blue align-left" @click="close()"><span>Retour</span></button>
        </div>
    </div>
</template>


<script>
import jsonQuiz from '@/data/quiz.json'
import { gsap, TweenLite } from "gsap";
import { Draggable } from "gsap/Draggable";
// import { EventBus } from '@/core/event-bus.js';

export default {
    name: 'QuizDragDropMain',
    data() {
        return {
            quizList: jsonQuiz,
            currentQuestion: 1, // on a un seul quiz de ce type

            lastPos: {x:0,y:0},
            currentHandle : null,
            success: false,
            good_reponses : {
                1 : "ulna",
                2 : "radio",
                3 : "carpo",
                4 : "meta",
                5 : "phalanges",
                6 : "radius",
                7 : "ligaments",
            },
            validated_reponses : [],
            reponses : {
                1 : null,
                2 : null,
                3 : null,
                4 : null,
                5 : null,
                6 : null,
                7 : null,
            },
            // fullImage: false,
            hasError: null,
            isAlreadyInDossier: false,
        }
    },
    mounted(){
        gsap.registerPlugin(Draggable);
        // Amount to offset control points
        this.handles = document.querySelectorAll(".handle");

        Draggable.create(this.handles, {
            type:"x,y",
            //onDrag: this.updatePath,
            bounds:"#ddzone",
            inertia:true,
            edgeResistance:0.65,
            onDragEnd:(e) => {
                if(e.target.classList && e.target.classList.contains('handle')) {

                    for(let i=1; i<8; i++){
                        if(this.reponses[i] == e.target.dataset.rep){
                            this.reponses[i] = null
                        }
                    }
                    if(Draggable.hitTest(document.getElementById('r1'), e.target) && this.reponses[1] == null) {
                        this.reponses[1] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r1').offsetLeft , y: document.getElementById('r1').offsetTop - e.target.offsetTop});
                    } else if(Draggable.hitTest(document.getElementById('r2'), e.target) && this.reponses[2] == null) {
                        this.reponses[2] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r2').offsetLeft , y:document.getElementById('r2').offsetTop - e.target.offsetTop});
                    } else if(Draggable.hitTest(document.getElementById('r3'), e.target) && this.reponses[3] == null) {
                        this.reponses[3] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r3').offsetLeft , y:document.getElementById('r3').offsetTop - e.target.offsetTop});
                    } else if(Draggable.hitTest(document.getElementById('r4'), e.target) && this.reponses[4] == null) {
                        this.reponses[4] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r4').offsetLeft , y:document.getElementById('r4').offsetTop- e.target.offsetTop });
                    } else if(Draggable.hitTest(document.getElementById('r5'), e.target) && this.reponses[5] == null) {
                        this.reponses[5] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r5').offsetLeft , y:document.getElementById('r5').offsetTop- e.target.offsetTop });
                    } else if(Draggable.hitTest(document.getElementById('r6'), e.target) && this.reponses[6] == null) {
                        this.reponses[6] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r6').offsetLeft , y:document.getElementById('r6').offsetTop- e.target.offsetTop });
                    } else if(Draggable.hitTest(document.getElementById('r7'), e.target) && this.reponses[7] == null) {
                        this.reponses[7] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r7').offsetLeft , y:document.getElementById('r7').offsetTop- e.target.offsetTop });
                    } else {
                        TweenLite.to(e.target,1,{ x:0 , y:0 });
                    }
                }
            }
        });


    },
    computed: {
        current() {
            return this.quizList[this.currentQuestion]
        },
    },
    methods : {
        close() {
            this.$emit('close')
        },
        addToDossier() {
            this.$store.commit("addToDossier", {dossier: this.current.ajoutDossier.dossier, item: this.current.ajoutDossier.item})
            this.isAlreadyInDossier = true
        },
        // showFullImage(){
        //     this.fullImage = true;
        // },
        // hideFullImage() {
        //     this.fullImage = false;
        // },
        validate() {
            if (this.reponses[1] == "ulna"
                && this.reponses[2] == "radio"
                && this.reponses[3] == "carpo"
                && this.reponses[4] == "meta"
                && this.reponses[5] == "phalanges"
                && this.reponses[6] == "radius"
                && this.reponses[7] == "ligaments"
            ) {
                this.validated_reponses = ["ulna", "radio", "carpo", "meta", "phalanges", "radius", "ligaments"]
                this.success = true;
                this.hasError = false
            } else {
                for (let i=1; i<8; i++) {
                    if (this.reponses[i] == this.good_reponses[i]) {
                        if (!this.validated_reponses.includes(this.reponses[i])) {
                            this.validated_reponses.push(this.reponses[i])
                        }
                    } else {
                        TweenLite.to(document.getElementById('q' + this.reponses[i]),1,{ x:0 , y:0 });
                        this.reponses[i] = null
                    }
                }

                this.hasError = true;
            }
        },

        isWellPlaced(rep){
            for(let i=1; i<8; i++){
                if(this.validated_reponses.includes(rep)){
                    return true;
                }
            }
            return false;
        },
    }
}
</script>

<style scoped lang="scss">  
    @import "@/assets/css/_variables.scss";

    .modal{
    pointer-events: auto;
    }

    .dragdrop {
        position: relative;

        .handle {
            position: absolute;
            top: 0;
            background: white;
            border: 1px solid black;
            border-radius: 3rem;
            color: black;
            cursor: move;
            display: block;
            font-size: 1.3rem;
            font-weight: 500;
            padding: 0.6rem 1rem;
            text-align: center;

            &.right {
                background: $green;
                border-color: $green;

                &:hover {
                    background: darken($green, 15%);
                }
            }
        }

        .q1 {
            top: 0;
        }

        .q2{
            top: 5rem;
        }
        .q3{
            top: 10rem;
        }
        .q4{
            top: 15rem;
        }
        .q5{
            top: 20rem;
        }
        .q6{
            top: 25rem;
        }
        .q7{
            top: 30rem;
        }
        .q8{
            top: 35rem;
        }

        .dropzone {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-left: 25rem;
        }

        .drop {
            padding-top: 4.2rem;
            flex-basis: 16.5rem;
            min-width: 16.5rem;

            li {
                background: rgba($grey, 0.5);
                border-radius: 3rem;
                height: 3rem;
                line-height: 3rem;
                margin-bottom: 1.5rem;

                &.r4 {
                    margin-bottom: 6.5rem;
                }
            }

            &.drop2 {
                padding-top: 5.5rem;

                li {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
</style>